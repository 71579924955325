import type { BrowserOptions, EdgeOptions, NodeOptions } from '@sentry/nextjs';

const config: BrowserOptions & NodeOptions & EdgeOptions = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.0005,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // uncomment the line below to enable Spotlight (https://spotlightjs.com)
  // spotlight: process.env.NODE_ENV === 'development',
  enabled: process.env.NODE_ENV !== 'development',

  initialScope: {
    tags: { 'app-version': 'legacy' },
  },
};

export default config;
